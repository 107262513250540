import { Component } from "react";

const ImgWithFallback = ({
  src,
  fallback,
  type = "image/webp",
  pictureClass = "",
  ...delegated
}) => {
  return (
    <picture className={pictureClass}>
      <source srcSet={src} type={type} />
      <img src={fallback} {...delegated} />
    </picture>
  );
};

export default ImgWithFallback;
