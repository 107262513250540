import { Component } from "react";
import ImgWithFallback from "./ImgWithFallback";
import LazyLoad from "react-lazyload";

const ProfilePicture = ({
  img,
  fallback,
  type = "image/webp",
  isleft = true,
  name,
  text,
  ...delegated
}) => {
  return (
    <div {...delegated}>
      <div
        className={`flex flex-col items-center md:items-start lg:flex-col lg:items-center ${
          isleft ? "md:flex-row" : "md:flex-row-reverse"
        }`}
      >
        <div className="flex flex-col md:h-full md:justify-center">
          <LazyLoad offset={300}>
            <ImgWithFallback
              src={img}
              fallback={fallback}
              alt="Picture of person"
              className="mb-7 mx-auto object-contain h-full drop-shadow-[0_0_10px_rgba(0,0,0,0.4)] md:mb-3"
            />
          </LazyLoad>
          <h3 className="mb-5 text-center font-bold tracking-wider text-lg md:whitespace-nowrap md:mb-0">
            {name}
          </h3>
        </div>
        <p
          className={`text-center md:text-left md:my-auto lg:text-center lg:overflow-visible 
        ${isleft ? "md:ml-10 lg:ml-0" : "md:mr-10 lg:mr-0"}`}
        >
          {text}
        </p>
      </div>
    </div>
  );
};

export default ProfilePicture;
