import ImgWithFallback from "./ImgWithFallback";

import logo_mantasaur_horizontal_coloured_fallback from "./images/logos/mantasaur/logo_mantasaur_horizontal_coloured.png";
import logo_mantasaur_horizontal_coloured from "./images/logos/mantasaur/logo_mantasaur_horizontal_coloured.webp";

import social_instagram_fallback from "./images/logos/socials/regular/Socials_Instagram.png";
import social_linkedin_fallback from "./images/logos/socials/regular/Socials_Linkedin.png";
import social_steam_fallback from "./images/logos/socials/regular/Socials_Steam.png";
import social_twitter_fallback from "./images/logos/socials/regular/Socials_Twitter.png";
import social_youtube_fallback from "./images/logos/socials/regular/Socials_Youtube.png";

import social_instagram from "./images/logos/socials/regular/Socials_Instagram.webp";
import social_linkedin from "./images/logos/socials/regular/Socials_Linkedin.webp";
import social_steam from "./images/logos/socials/regular/Socials_Steam.webp";
import social_twitter from "./images/logos/socials/regular/Socials_Twitter.webp";
import social_youtube from "./images/logos/socials/regular/Socials_Youtube.webp";

const url_youtube_trailer = "https://www.youtube.com/watch?v=ay9ion6X08k";
const url_steamstore = "https://store.steampowered.com/app/2122880/All_Hands_on_Deck/";
const url_instagram = "https://www.instagram.com/studiomantasaur";
const url_linkedin = "https://nl.linkedin.com/company/studio-mantasaur";
const url_twitter = "https://twitter.com/StudioMantasaur";

const socials_regular = [
  [social_youtube, social_youtube_fallback, url_youtube_trailer, "Youtube"],
  [social_steam, social_steam_fallback, url_steamstore, "Steam"],
  [social_instagram, social_instagram_fallback, url_instagram, "Instagram"],
  [social_linkedin, social_linkedin_fallback, url_linkedin, "LinkedIn"],
  [social_twitter, social_twitter_fallback, url_twitter, "Twitter"],
];

const navmap = [
  ["Home", "#top", ""],
  ["All Hands on Deck", "#trailer", ""],
  ["The Team", "#theteam", ""],
  ["Presskit", "https://studiomantasaur.nl/press/", "_blank"],
];

import React, { useState } from "react";
import SocialsRow from "./SocialsRow";

const ResponsiveHeader = () => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      {/* <!-- Mobile Overlay --> */}
      <div
        className={`h-full w-full mt-16 pb-16 bg-white fixed flex flex-col place-content-between z-30 top-0 transition duration-300 ease-out"
        ${open ? "opacity-100 visible" : "opacity-0 invisible"}`}
      >
        {/* <!-- mobile menu --> */}
        <ul className="m-auto h-full flex flex-col space-y-10 justify-center text-center font-varela-round text-2xl">
          {navmap.map(([title, url, target]) => (
            <li key={title}>
              <a href={url} target={target} onClick={() => setOpen(!open)}>
                <button>{title}</button>
              </a>
            </li>
          ))}
        </ul>

        <SocialsRow className="invert mb-12 w-[80vw] sm:w-[60vw]" />
      </div>

      {/* <!-- Navbar --> */}
      <nav className="h-16 w-full bg-white shadow-lg z-20 fixed top-0">
        <div className="flex px-4 h-full">
          {/* <!-- Website Logo --> */}
          <a href="#" className="flex items-center px-2 h-full">
            <ImgWithFallback
              src={logo_mantasaur_horizontal_coloured}
              fallback={logo_mantasaur_horizontal_coloured_fallback}
              alt="Logo"
              className="h-10 mr-2"
            />
          </a>

          {/* <!-- Navbar button --> */}
          <div className="hidden md:flex items-center space-x-1">
            {navmap.map(([title, url, target]) => (
              <a key={title} href={url} target={target} className="h-full">
                <button className="h-full w-full hover:bg-gray-200 px-2 lg:px-8">
                  {title}
                </button>
              </a>
            ))}
          </div>

          {/* <!-- Socials --> */}
          <div className="hidden md:flex ml-auto flex-row py-3 space-x-2 lg:space-x-4">
            {socials_regular.map(([img, fallbackimg, url, alt]) => (
              <button key={alt} className="btn-outline w-6 lg:w-8">
                <a href={url}>
                  <ImgWithFallback
                    src={img}
                    fallback={fallbackimg}
                    alt={alt}
                    className="h-full w-full invert brightness-40 hover:brightness-75 object-contain"
                  />
                </a>
              </button>
            ))}
          </div>

          {/* <!-- Hamburger button --> */}
          <div className="flex ml-auto flex-col justify-center py-6 md:hidden">
            <div className="relative py-3 mx-auto sm:max-w-xl">
              <nav>
                <button
                  className="relative w-10 h-10 text-gray-500 bg-white rounded-sm focus:outline-none"
                  onClick={() => setOpen(!open)}
                >
                  <span className="sr-only">Open main menu</span>
                  <div className="absolute block w-5 transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
                    <span
                      aria-hidden="true"
                      className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${
                        open ? "rotate-45" : "-translate-y-1.5"
                      }`}
                    ></span>
                    <span
                      aria-hidden="true"
                      className={`block absolute  h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${
                        open && "opacity-0"
                      }`}
                    ></span>
                    <span
                      aria-hidden="true"
                      className={`block absolute  h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${
                        open ? "-rotate-45" : "translate-y-1.5"
                      }`}
                    ></span>
                  </div>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default ResponsiveHeader;
