import ImgWithFallback from "./ImgWithFallback";
import social_instagram_circular_fallback from "./images/logos/socials/circular/Socials_Instagram_Circular.png";
import social_linkedin_circular_fallback from "./images/logos/socials/circular/Socials_Linkedin_Circular.png";
import social_steam_circular_fallback from "./images/logos/socials/circular/Socials_Steam_Circular.png";
import social_twitter_circular_fallback from "./images/logos/socials/circular/Socials_Twitter_Circular.png";
import social_youtube_circular_fallback from "./images/logos/socials/circular/Socials_Youtube_Circular.png";

import social_instagram_circular from "./images/logos/socials/circular/Socials_Instagram_Circular.webp";
import social_linkedin_circular from "./images/logos/socials/circular/Socials_Linkedin_Circular.webp";
import social_steam_circular from "./images/logos/socials/circular/Socials_Steam_Circular.webp";
import social_twitter_circular from "./images/logos/socials/circular/Socials_Twitter_Circular.webp";
import social_youtube_circular from "./images/logos/socials/circular/Socials_Youtube_Circular.webp";

const url_youtube_trailer = "https://www.youtube.com/watch?v=ay9ion6X08k";
const url_steamstore = "https://store.steampowered.com/app/2122880/All_Hands_on_Deck/";
const url_instagram = "https://www.instagram.com/studiomantasaur";
const url_linkedin = "https://nl.linkedin.com/company/studio-mantasaur";
const url_twitter = "https://twitter.com/StudioMantasaur";

const socials_circular = [
  [
    social_youtube_circular,
    social_youtube_circular_fallback,
    url_youtube_trailer,
    "Youtube",
  ],
  [
    social_steam_circular,
    social_steam_circular_fallback,
    url_steamstore,
    "Steam",
  ],
  [
    social_instagram_circular,
    social_instagram_circular_fallback,
    url_instagram,
    "Instagram",
  ],
  [
    social_linkedin_circular,
    social_linkedin_circular_fallback,
    url_linkedin,
    "LinkedIn",
  ],
  [
    social_twitter_circular,
    social_twitter_circular_fallback,
    url_twitter,
    "Twitter",
  ],
];

import { Component } from "react";
import LazyLoad from "react-lazyload";

class SocialsRow extends Component {
  render() {
    return (
      <div
        className={`flex flex-row mx-auto h-11 justify-center space-x-3 ${this.props.className}`}
      >
        {socials_circular.map(([img, fallbackimg, url, alt]) => (
          <a key={alt} href={url} className="w-1/6">
            <LazyLoad offset={300}>
              <ImgWithFallback
                src={img}
                fallback={fallbackimg}
                alt={alt}
                className="object-contain"
              />
            </LazyLoad>
            {/* <img src={img} alt={alt} className="object-contain" /> */}
          </a>
        ))}
      </div>
    );
  }
}

export default SocialsRow;
